import { Face, Iframe } from '@haechi-labs/face-sdk';
import {
  assert,
  ConnectedWallet,
  ConnectionResult,
  invalidWallet,
  JsonRpcMethod,
  kitProvidersAndWalletsConfigError,
  LoginProviderType,
  Wallet,
  WalletMetaData,
} from '@haechi-labs/face-types';
import { ConnectorData } from '@wagmi/connectors';

import { FaceWalletConnector } from './connectors/FaceWalletConnector';
import { getFaceWallet } from './getDefaultWallets';

export type KitConfig = {
  providers?: LoginProviderType[];
  externalWalletOptions?: {
    wallets?: Wallet[];
    expanded?: boolean;
  };
};

const WALLET_CONNECT_ZINDEX = 89;
const FACE_WALLET_ZINDEX = 2147483647;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getWalletMetaData({ createConnector, ...walletInfo }: Wallet): WalletMetaData {
  return walletInfo;
}

export class Kit {
  private face: Face;
  private iframe: Iframe;
  private config: KitConfig | undefined;

  constructor(face: Face, config?: KitConfig) {
    assert(
      config?.externalWalletOptions?.wallets?.length || config?.providers?.length !== 0,
      kitProvidersAndWalletsConfigError
    );

    this.face = face;
    this.config = config;
    this.iframe = face.internal.iframe;
  }

  async connect(): Promise<ConnectedWallet> {
    const { providers, externalWalletOptions } = this.config || {};
    const { wallets, expanded } = externalWalletOptions || {};
    let selectedWallet: Wallet | undefined;
    let connector;
    let result: ConnectionResult | undefined;

    const handleConnection = async (e: MessageEvent) => {
      if (e.origin !== this.iframe.iframeUrl) {
        return;
      }
      const message = e.data;
      switch (message.method) {
        case JsonRpcMethod.face_connectExternalWallet:
          const selectedWalletId = message.params?.[0]?.walletId;
          selectedWallet = wallets?.find((w) => w.id === selectedWalletId);

          if (selectedWallet) {
            try {
              const isWalletConnect = selectedWallet.id.includes('walletConnect');
              const iframe = document.getElementById('face-iframe');
              if (isWalletConnect && iframe) {
                iframe.style.zIndex = `${WALLET_CONNECT_ZINDEX - 1}`;
              }
              const chainId = await this.face.getChainId();
              connector = await (selectedWallet as Wallet).createConnector();
              result = await connector.connect({
                chainId,
              });

              if (isWalletConnect && iframe) {
                iframe.style.zIndex = `${FACE_WALLET_ZINDEX}`;
              }
              await this.iframe.sendChildMessage({
                id: message.id,
                result: {
                  chain: result.chain,
                  account: result.account,
                } as ConnectionResult,
              });
            } catch (error: any) {
              await this.iframe.sendChildMessage({
                id: message.id,
                error: JSON.parse(JSON.stringify(error)),
              });
            }
          }
          break;
      }
    };
    window.addEventListener('message', handleConnection);

    try {
      const requestId = await this.iframe.sendChildMessage({
        method: JsonRpcMethod.face_openKit,
        params: [{ providers, wallets: wallets?.map(getWalletMetaData), expanded }],
      });
      const response = await this.iframe.waitForResponse<any | ConnectorData>(requestId);

      if (response?.[0]?.faceUserId) {
        const { createConnector, ...faceWalletMetaData } = await getFaceWallet({ face: this.face });
        return {
          ...faceWalletMetaData,
          connector: new FaceWalletConnector({ options: { face: this.face } }),
        };
      } else if (response.account === result?.account && connector && selectedWallet) {
        return { ...getWalletMetaData(selectedWallet), connector };
      }

      throw invalidWallet();
    } catch (e) {
      throw e;
    } finally {
      window.removeEventListener('message', handleConnection);
    }
  }
}
